import React, { useEffect } from "react"
import { navigate } from "gatsby"

import Layout from "../components/layout"
import Quotes from "../components/quotes"
import SEO from "../components/seo"

import Grid from '@material-ui/core/Grid';
import { CircularProgress } from '@material-ui/core';
// import pencil from "../images/pencils.png"

import { Query } from 'react-apollo';
import gql from 'graphql-tag';

import DOMPurify from 'dompurify';
import Helmet from "react-helmet"


const APOLLO_QUERY_ABOUT = gql`
  {
  	 homepages {
	    nodes {
	      content
	    }
	 }

  }
`

const gotoLink = (url) => {

	navigate(url)

}

let contentAbout = <div style={{ padding:'30% 45%' }}><CircularProgress color="inherit" /></div>;
const sanitizer = DOMPurify.sanitize;

const IndexPage = () => {

	useEffect(() => {
		
		// document.body.style.backgroundColor = 'hsla(0,0%,100%,0.3)';

	})

	return (
	  <Layout>
	    <SEO title="Home" />

	    <Helmet>
            {/* <!-- Primary Meta Tags --> */}
            <title>Experienced, reliable copywriter in Bucks</title>
            <meta name="title" content="Experienced, reliable copywriter in Bucks" />
            <meta name="description" content="You’ve found me! As an experienced copywriter, I can help you with writing blogs, editing copy or developing a tone of voice for your brand." />
        </Helmet>

	    <Grid container className="main">

	    	<Grid container className="homepage">
	    		<h1>No Fluff, No Jargon</h1>
	    		<Grid container className="who_is">

	    			<Grid item className="who_is_text">

	    				<h2>Just engaging copy that works</h2>
	
						 <Query query={APOLLO_QUERY_ABOUT}>

		                      {({data}) => {

		                      if(data){

		                      	contentAbout = <div dangerouslySetInnerHTML={{ __html: sanitizer(data.homepages.nodes[0].content)}} />
		                       
		                      }

		                      return contentAbout

		                    }}

		                </Query>

					</Grid>
					
					<Grid item className="who_is_images">
	    				<Grid item className="who_is_photo"><div className="tape"></div></Grid>
	    				<Grid item className="badge_pro_copywriter_member"></Grid>
	    				<Grid item className="badge_policybee"></Grid>
	    				<Grid onClick={() => gotoLink('/work/')} item className="black_button button_big">View my work</Grid>
	    			</Grid>

	    		</Grid>


	    		{/*<Grid container className="copywriting">

	    			<Grid item className="who_is_text_small">
	    				<p className="orange_handwriting">Copywriting</p>
	    			</Grid>
	    		</Grid>

	    		<Grid container className="copywriting">

	    			<Grid item className="who_is_text_small">
	    				<p className="orange_handwriting">Copy editing</p>
	    			</Grid>
	    		</Grid>

	    		<Grid container className="copywriting">

	    			<Grid item className="who_is_text_small">
	    				<p className="orange_handwriting">View my work</p>
	    			</Grid>
	    		</Grid>*/}


	    		<Grid container className="who_is">

	    			<Grid item className="who_is_text2">


	    				<Grid onClick={() => gotoLink('/hireme#copywriting')} item className="orange_handwriting">Copywriting</Grid>
	    				<Grid onClick={() => gotoLink('/hireme#copyediting')} item className="orange_handwriting">Copy editing</Grid>
	    				{/*<Grid item className="orange_handwriting">View my work</Grid>*/}


	    			</Grid>


	    			<Grid item className="who_is_text2">

	    			<Grid container className="quotes_holder">
						
						<Quotes />

		    		</Grid>


	    			</Grid>

	    		</Grid>

	    		{/*<Grid container className="linksHolder">
	    			<Grid item className="who_is_text_small">
	    				<p className="orange_handwriting">Copywriting</p>
	    			</Grid>
	    			<Grid item className="who_is_text_small">
	    				<p className="orange_handwriting">Copy editing</p>
	    			</Grid>

	    			<Grid item className="who_is_text_small">
	    				<p className="orange_handwriting">View my work</p>
	    			</Grid>
	    		</Grid>*/}

	    		{/*<Grid container className="who_is">
	    		<Grid container className="quotes_holder">
						
					<Quotes />

	    		</Grid>
	    		pppp
	    		</Grid>*/}

	    	</Grid>

	    </Grid>
	    
	  </Layout>
	)

}

export default IndexPage
